import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;
  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledSpan = styled.span`
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  display: inline-block;
  transition: all 0.3s ease-out;

  &:hover {
    color: #64fedb;
    animation-name: rubberBand;
  }

  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;
  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }
  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);
    &:hover,
    &:focus {
      background: transparent;
      outline: 0;
      &:after {
        top: 15px;
        left: 15px;
      }
      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }
    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }
    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }
    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'JavaScript',
    'TypeScript',
    'React',
    'Python',
    'Docker',
    'AWS',
    'DynamoDB',
    'Flask',
    'DataGrip',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">
        <StyledSpan>M</StyledSpan>
        <StyledSpan>e</StyledSpan>
        <StyledSpan>,</StyledSpan>&nbsp;
        <StyledSpan>M</StyledSpan>
        <StyledSpan>y</StyledSpan>
        <StyledSpan>s</StyledSpan>
        <StyledSpan>e</StyledSpan>
        <StyledSpan>l</StyledSpan>
        <StyledSpan>f</StyledSpan>
        <StyledSpan>,</StyledSpan>&nbsp;
        <StyledSpan>&</StyledSpan>&nbsp;
        <StyledSpan>I</StyledSpan>
      </h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello there! I'm Mytruc (pronounced mii-chew), a software engineer who thrives on
              precision and detail. My interests are diverse, spanning from coding to photography,
              music, gaming, and travel. I pride myself on being a fast learner, perpetually excited
              to absorb new knowledge in the ever-evolving world of tech and artificial
              intelligence.
            </p>
            <p>
              My professional background originates from the service industry, an experience that
              has instilled in me a profound respect for customer service, teamwork, clear
              communication, and a focus on results. I'm drawn to challenging problems, using them
              as stepping stones to broaden my horizons personally and professionally.
            </p>

            <p>
              Pursuing my PharmD degree at <a href="https://www.regis.edu/">Regis University</a> was
              an enriching experience, yet I found myself increasingly drawn towards the realm of
              technology. In response to this newfound interest, I immersed myself in{' '}
              <a href="https://www.codingdojo.com/">Coding Dojo</a>, an intensive full-time
              bootcamp. Here, I accumulated over 1,000 hours of hands-on coding experience and
              successfully developed more than 40 web applications. In my endeavor to expand my
              knowledge base, I secured a position in the Bachelor of Science program in Computer
              Science at <a href="https://www.asu.edu/">Arizona State University (ASU)</a>, where
              I'm set to graduate by 2027. My academic ambitions, however, do not end there. I
              intend to further my education by progressing onto{' '}
              <a href="https://www.asu.edu/">ASU's</a> Master of Computer Science program, enabling
              me to delve even deeper into the field I've grown so passionate about.
            </p>

            <p>
              Fast-forward to today, and I've had the privilege of working at{' '}
              <a href="https://radius.ai/">a early stage start-up</a> company and{' '}
              <a href="https://proctorio.com/">a late stage start-up</a> company as a software
              engineer.
            </p>

            <p>Here are a few technologies I've been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
