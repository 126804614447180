import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
import Typewriter from 'typewriter-effect';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;
const StyledSpan = styled.span`
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  display: inline-block;
  transition: all 0.3s ease-out;

  &:hover {
    color: #64fedb;
    animation-name: rubberBand;
  }

  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }
`;
const StyledTypewritter = styled.h2`
  margin: 0;
  font-size: clamp(30px, 7vw, 50px);
  display: flex;

  background: linear-gradient(271deg, #a0e9ff 30%, #a162e8 50%, #f093b0 70%, #edca85 94%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi, my name is</h1>;
  const two = (
    <h2 className="big-heading">
      <StyledSpan>M</StyledSpan>
      <StyledSpan>y</StyledSpan>
      <StyledSpan>t</StyledSpan>
      <StyledSpan>r</StyledSpan>
      <StyledSpan>u</StyledSpan>
      <StyledSpan>c</StyledSpan>&nbsp;
      <StyledSpan>N</StyledSpan>
      <StyledSpan>g</StyledSpan>
      <StyledSpan>u</StyledSpan>
      <StyledSpan>y</StyledSpan>
      <StyledSpan>e</StyledSpan>
      <StyledSpan>n</StyledSpan>
    </h2>
  );
  const four = (
    <StyledTypewritter>
      I'm a&nbsp;
      <Typewriter
        options={{
          strings: [
            'Front-end Developer...',
            'Backend Developer...',
            'Full Stack Developer...',
            'Data Visualization Specialist...',
          ],
          autoStart: true,
          loop: true,
        }}
      />
    </StyledTypewritter>
  );
  const three = <h3 className="medium-heading">I build things for the web and for fun.</h3>;

  const items = [one, two, three, four];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
