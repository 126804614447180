import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledSkillSection = styled.section`
  max-width: 900px;
  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  display: flex;
  margin-top: 24px;
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledSpan = styled.span`
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  display: inline-block;
  transition: all 0.3s ease-out;

  &:hover {
    color: #64fedb;
    animation-name: rubberBand;
  }

  @keyframes rubberBand {
    from {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }
`;

const Skills = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const Programming_Languages = [
    'C#',
    'CSS',
    'HTML',
    'Java',
    'JavaScript',
    'Python',
    'Ruby',
    'SQL',
    'TypeScript',
  ];
  const Libraries = ['jQuery', 'Material UI', 'React', 'Redux', 'Mongoose'];
  const Frameworks = [
    '.NET',
    'Bootstrap',
    'Django',
    'Express',
    'Flask',
    'Gatsby',
    'Next.js',
    'Spring',
    'Tailwind',
  ];
  const Database = ['MongoDB', 'Mongoose', 'MySQL', 'PostgreSQL'];
  const Tools = [
    'AJAX',
    'API',
    'MySQL',
    'Azure DevOps',
    'DataGrip',
    'Docker',
    'Eclipse',
    'Firebase',
    'Git',
    'GitHub',
    'Jest',
    'JSON',
    'Kubernetes',
    '.NET Core',
    'Netlify',
    'Node.js',
    'Postman',
  ];

  return (
    <StyledSkillSection id="skills" ref={revealContainer}>
      <h2 className="numbered-heading">
        <StyledSpan>S</StyledSpan>
        <StyledSpan>k</StyledSpan>
        <StyledSpan>i</StyledSpan>
        <StyledSpan>l</StyledSpan>
        <StyledSpan>l</StyledSpan>
        <StyledSpan>s</StyledSpan>
      </h2>

      <div className="inner">
        <StyledText>
          <div>
            <h3>Programming Languages</h3>
            <ul className="skills-list">
              {Programming_Languages &&
                Programming_Languages.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledText>
        <StyledText>
          <div>
            <h3>Libraries</h3>
            <ul className="skills-list">
              {Libraries && Libraries.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledText>
      </div>
      <div className="inner">
        <StyledText>
          <div>
            <h3>Frameworks</h3>
            <ul className="skills-list">
              {Frameworks && Frameworks.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledText>
        <StyledText>
          <div>
            <h3>Database</h3>
            <ul className="skills-list">
              {Database && Database.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledText>
      </div>
      <div className="inner">
        <StyledText>
          <div>
            <h3>Tools</h3>
            <ul className="skills-list">
              {Tools && Tools.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledText>
      </div>
    </StyledSkillSection>
  );
};

export default Skills;
