module.exports = {
  email: 'mytruc17@gmail.com',

  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://www.github.com/MytrucNguyen',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/mytrucnguyen.dev/',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/mytrucnguyen/',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/mytrucnguyen',
    },
    {
      name: 'Replit',
      url: 'https://replit.com/@MytrucNguyen',
    },
    {
      name: 'LeetCode',
      url: 'https://leetcode.com/mytrucnguyen/',
    },
    {
      name: 'CodeSandbox',
      url: 'https://codesandbox.io/u/MytrucNguyen',
    },
    {
      name: 'SoundCloud',
      url: 'https://soundcloud.com/miichew/tracks',
    },
  ],

  navLinks: [
    {
      name: 'About Me',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#experience',
    },
    {
      name: 'Projects',
      url: '/#projects',
    },
    {
      name: 'Skills',
      url: '/#skills',
    },
    {
      name: 'Hobbies',
      url: '/#hobbies',
    },
  ],

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
