import React from 'react';

const IconSoundCloud = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    width="512"
    height="512">
    <title>SoundCloud</title>
    <path d="M10.069,16.995L9.931,14.43l0.138-6.345  c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,6.345l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405  C10.254,17.4,10.069,17.219,10.069,16.995z M8.414,16.995L8.276,14.43l0.138-4.725c0-0.224,0.185-0.405,0.414-0.405  c0.228,0,0.414,0.181,0.414,0.405l0.138,4.725l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405C8.599,17.4,8.414,17.219,8.414,16.995z   M6.759,16.995L6.621,14.43l0.138-5.265c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,5.265l-0.138,2.565  c0,0.224-0.185,0.405-0.414,0.405C6.944,17.4,6.759,17.219,6.759,16.995z M5.103,16.995L4.966,14.43l0.138-4.185  c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,4.185l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405  C5.289,17.4,5.103,17.219,5.103,16.995z M3.448,16.995L3.31,14.43l0.138-2.025C3.448,12.181,3.634,12,3.862,12  s0.414,0.181,0.414,0.405l0.138,2.025l-0.138,2.565c0,0.224-0.185,0.405-0.414,0.405S3.448,17.219,3.448,16.995z M1.793,16.995  L1.655,14.43l0.138-2.565c0-0.224,0.185-0.405,0.414-0.405c0.228,0,0.414,0.181,0.414,0.405l0.138,2.565l-0.138,2.565  c0,0.224-0.185,0.405-0.414,0.405C1.978,17.4,1.793,17.219,1.793,16.995z M0.138,15.915L0,14.43l0.138-1.485  c0-0.224,0.185-0.405,0.414-0.405s0.414,0.181,0.414,0.405l0.138,1.485l-0.138,1.485c0,0.224-0.185,0.405-0.414,0.405  S0.138,16.139,0.138,15.915z M19.515,11.131c0.365-0.136,0.761-0.211,1.175-0.211c1.828,0,3.31,1.451,3.31,3.24  s-1.482,3.24-3.31,3.24h-8.275c-0.457,0-0.828-0.364-0.828-0.811V7.95c0-1.35,2.483-1.35,2.483-1.35  C16.814,6.6,19.09,8.562,19.515,11.131z" />
  </svg>
);

export default IconSoundCloud;
