import React from 'react';

const IconGitHub = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512">
    <title>CodeSandbox</title>
    <g id="_01_align_center" data-name="01 align center">
      <path d="M24,24H0V0H24ZM2,22H22V2H2Z" />
    </g>
  </svg>
);

export default IconGitHub;
